<template>
  <div>
    <GlobalEvents
      @keyup.escape="clearSuggestedCompanyRegistrations"
      @click="handleClickOutside"
    />
    <UpsellBanner
      class="upsell-banner__margin"
      :description="upsellBannerDescription"
    />
    <ShopDetailsForm
      ref="shopDetailsForm"
      :shop-payment-infos="shopsPaymentInfos[0]"
      @toggle-submit="toggleSubmit"
    />
    <div v-if="!isBillingOnOrganisation">
      <p class="sk-text-x-small-regular">
        {{ $t('create_shop.disclamer') }}
      </p>
      <!-- eslint-disable vue/no-v-html -->
      <p
        class="sk-text-x-small-regular"
      >
        <i18n
          path="create_shop.terms_of_service"
        >
          <template #linkTerms>
            <SkOroraLink
              target="_blank"
              rel="noopener noreferrer"
              size="x-small"
              :href="$t('create_shop.link_terms_of_service')"
            >
              {{ $t('create_shop.text_terms_of_service') }}
            </SkOroraLink>
          </template>
          <template #linkPrivacy>
            <SkOroraLink
              target="_blank"
              rel="noopener noreferrer"
              size="x-small"
              :href="$t('create_shop.link_privacy_policy')"
            >
              {{ $t('create_shop.text_privacy_policy') }}
            </SkOroraLink>
          </template>
        </i18n>
      </p>
    <!-- eslint-enable vue/no-v-html -->
    </div>
    <div v-if="displayPaymentByBankTransfer">
      <p class="sk-text-large-semibold bank-transfert-payment-label">
        {{ $t('fill_billing_info.billing_line.bank_transfer.payment_method') }}
      </p>
      <p class="sk-text-medium-regular bank-transfert-description ">
        {{ $t('fill_billing_info.billing_line.bank_transfer.payment_method_label') }}
        <span class="span-bank-transfer">
          {{ $t('fill_billing_info.billing_line.bank_transfer.payment_method_span') }}
        </span>
        <br>
        {{ $t('fill_billing_info.billing_line.bank_transfer.payment_method_description') }}
      </p>
    </div>
  </div>
</template>
<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import GlobalEvents from 'vue-global-events';

import UpsellBanner from '@components/UpsellBanner';
import ShopDetailsForm from '@components/ShopDetailsForm';
import dayjs from 'dayjs';
import { extractPrice } from '@/shared/utils/method_helper';

export default {
  name: 'CreateShop',
  components: { GlobalEvents, UpsellBanner, ShopDetailsForm },
  computed: {
    ...mapGetters(['isBillingOnOrganisation', 'displayPaymentByBankTransfer']),
    ...mapState(['quote', 'shopsPaymentInfos']),

    newPackDate() {
      return dayjs(this.quote.contract_terms.contract_term_start).locale(this.$i18n.locale).format('DD MMMM YYYY');
    },
    addedPrice() {
      return extractPrice(this.quote, this.quote.totals.total_amount);
    },
    packName() {
      return this.quote.quote_line_items.find(elem => elem.title.includes('Pack')).title;
    },
    listOption() {
      return this.quote.quote_line_items.filter(elem => elem.type.includes('Option')).map(elem => elem.title);
    },
    lastOption() {
      return this.listOption[this.listOption.length - 1];
    },
    translateListOptionWithoutLastElement() {
      if (this.listOption.length === 1) {
        return this.listOption[0];
      }
      return this.listOption.slice(0, this.listOption.length - 1).join(', ');
    },
    upsellBannerTranslateParam() {
      return {
        start_date: this.newPackDate,
        pack_name: this.packName,
        options: this.translateListOptionWithoutLastElement,
        price: this.addedPrice,
        last_option: this.lastOption,
      };
    },
    upsellBannerDescription() {
      const key = this.quote.type_of_payment === 'Shop' ? 'shop' : 'organisation';
      const engagmentUnit = this.quote.contract_terms.total_engagement_unit;

      return this.$tc(`upsell_banner_${engagmentUnit}.${key}_payment_description`, this.listOption.length, this.upsellBannerTranslateParam);
    },
  },
  methods: {
    handleClickOutside(event) {
      if (this.$refs.shopDetailsForm.$refs.dropdown?.$el?.contains(event.target)) return;

      this.$refs.shopDetailsForm.clearSuggestedCompanyRegistrations();
    },
    clearSuggestedCompanyRegistrations() {
      this.$refs.shopDetailsForm.clearSuggestedCompanyRegistrations();
    },
    toggleSubmit(value) {
      if (value) {
        this.$emit('disable-submit');
      } else {
        this.$emit('enable-submit');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upsell-banner__margin {
  margin-top: 24px;
}

.span-bank-transfer {
  font-weight: 600;
}
.bank-transfert-description {
  margin-top: 16px;
  margin-bottom: 0px;
}
.bank-transfert-payment-label {
  margin-top: 32px;
  margin-bottom: 0px;
}
</style>
